import React from "react";

const HeartDisease = () => {
  return (
    <section
      className="mt-4 p-0 gap-5 flex flex-col md:flex-row mb-4 rounded-lg bg-white "
      style={{ marginTop: 20 }}
      
    >
      <div className="w-full md:w-2/3 overflow-hidden ">
        <img
          src="../assets/New-Hero//vision.png"
          alt="Heart"
          className="w-3/3 object-contain rounded-lg"
          
        />
      </div>

      <div className="w-full md:w-full lg:w-full lg:-mt-20 sm:-mt-32 md:-mt-30  ml-10 ">
        <h1
          // data-aos="fade-left"
          className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-0 uppercase lg:mt-32  sm:mt-40 ml-3"
        >
         Mission
        </h1>
        <p
          data-aos="fade-left"
          className="text-gray-600 xl:text-md text-justify lg:text-md md:text-md sm:p-4"
        >
         Provides patients with the necessary information, resources, and support to effectively manage diabetes. It also serves as an organ protection resource center for the patients.
  
        </p> 

        <h1
          // data-aos="fade-left"
          className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-0 uppercase mt-10 ml-3"
        >
         Vision
        </h1>
        <p
          data-aos="fade-left"
          className=" text-gray-600 xl:text-md text-justify lg:text-md md:text-md sm:p-4"
        >
           To empower patients to lead a healthy, fulfilling life through effectively managing diabetes. To create a culture of health and well-being that prioritizes the prevention of organ damage and minimizes the need for organ transplantation. 
        </p> 
      </div>
    </section>
  );
};

export default HeartDisease;
