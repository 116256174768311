import React, { useState } from "react";
import { IoMdMail } from "react-icons/io";
import { MdSupportAgent } from "react-icons/md";
import MainChatBot from "../Chatbot/MainChatbot";
import {
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaFacebookF,
  FaLocationDot,
  FaPhoneFlip,
  FaCircleArrowRight,
} from "react-icons/fa6";

import {
  FaPhoneSquareAlt,
  FaArrowUp,
  FaFacebook,  
  FaLinkedin,
} from "react-icons/fa";

import { IoMdMailOpen } from "react-icons/io";
import { MdAccessTimeFilled } from "react-icons/md";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

const NewFooter = () => {
  const [saving, setSaving] = useState(false);
  const [isChatOn, setIsChatOn] = useState(false);

  const initialValues = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (saving) {
      return;
    }
    setSaving(true);

    try {
      const res = await fetch(
        process.env.REACT_APP_BASE_API_URL + "/enquiries",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        }
      );
      const data = await res.json(); 

      if (res.status !== 200) {
        const error = new Error(data.error || "Failed to submit enquiry");
        throw error;
      }
      resetForm();
      toast.success("Thank you for your enquiry!");
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false); // Always set saving state back to false
    }
  };

  const menuItems = [
    {
      id: 1,
      menu: "Home",
      url: "/home",
    },
    {
      id: 2,
      menu: "Introduction",
      url: "/introduction",
    },
    {
      id: 3,
      menu: "FAQ",
      url: "/faqs-diabetes",
    },
    {
      id: 4,
      menu: "Diet Charts",
      url: "/dietchart",
    },

    {
      id: 5,
      menu: "About Micro Labs Ltd",
      url: "https://www.microlabsltd.com/about-micro-labs",
    },

    {
      id: 5,
      menu: "About Us",
      url: "/introduction",
    },


     
  ];

  const handleIconHover = (message) => {
    console.log(message);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  return (
    <>
      <footer className="bg-primary p-6 md:p-10 xl:p-20">
        <div
          className="grid grid-cols-1 lg:grid-cols-12 lg:gap-0 gap-6 mx-auto space-x-0 md:space-x-5"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="1000"
          crossOrigin="anonymous"
        >
          <div className="space-y-4 col-span-4 lg:col-span-3 ml-15">
            <img
              src="/assets/Logo.png"
              alt="logo"
              className="w-[160px] mx-auto md:mx-0 mb-5 "
              style={{marginLeft : 32}}
            />

            {/* <p className="text-white font-inter font-normal text-sm w-full text-justify lg:text-start">
              Nearly 1.13 billion of the global population living in low- and
              middle-income countries is estimated to have hypertension or high
              blood pressure. It is significant in the view that hypertension's
              effect on the heart may contribute to a number of heart-related
              problems. Lorem ipsum, dolor sit amet consectetur adipisicing
              elit. Molestiae aspernatur officia dicta iusto qui a.
            </p>
            <span className="text-white">Visitor :</span> */}
            <div className="flex items-center gap-2 justify-center md:justify-start">
              <a
                href="https://www.instagram.com/mission_organ_protection/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram
                  size={50}
                  className="text-white cursor-pointer  bg-teal-800 p-2 rounded-2xl transition duration-300 ease-in-out transform hover:scale-110"
                />
              </a>
              <a
                href="https://www.linkedin.com/in/mission-organ-protection-a63309314/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn
                  size={50}
                  className="text-white cursor-pointer  bg-teal-800 p-2 rounded-2xl transition duration-300 ease-in-out transform hover:scale-110"
                />
              </a>
              <a href="https://www.youtube.com/playlist?list=PLKb6HsmFgC8svn6ku5toXg70DmaQI_0KD" target="_blank" rel="noopener noreferrer">
                <FaYoutube
                  size={50}
                  className="text-white cursor-pointer  bg-teal-800 p-2 rounded-2xl transition duration-300 ease-in-out transform hover:scale-110"
                />
              </a>
              
              <a
                href="https://www.facebook.com/mission.organ.protection"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF
                  size={50}
                  className="text-white cursor-pointer transition duration-300 ease-in-out transform hover:scale-110 bg-teal-800 p-2 rounded-2xl "
                />
              </a>
            </div>
          </div>

          <div className="col-span-2">
            <h2 className="capitalize text-white font-inter mb-6 md:mb-10 font-semibold text-xl text-center md:text-left">
              Quick Links
            </h2>

            <ul className="text-white list-none font-inter text-sm font-normal space-y-2 md:space-y-4">
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  className="flex items-center justify-center md:justify-start"
                >
                  <a href={item.url}>{item.menu}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-span-3 lg:col-span-4">
            <h2 className="capitalize text-white font-inter font-semibold text-xl mb-6 md:mb-10 text-center md:text-left">
              Contact Details
            </h2>

            <div className="space-y-5">
              <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                <div>
                  <FaLocationDot size={20} className="text-white" />
                </div>
                <span className="text-center md:text-left">
                  Race Course Rd, Madhava Nagar, Gandhi Nagar, <br /> Bengaluru,
                  Karnataka 560001
                </span>
              </div>

              <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                <div>
                  <IoMdMailOpen size={20} className="text-white" />
                </div>
                <span>info@missionorganprotection.com</span>
              </div>

              {/* <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                <div>
                  <FaPhoneFlip size={20} className="text-white" />
                </div>
                <span>+91-80-2237 0451-57, +91-80-2237 0463</span>
              </div> */}

              {/* <div className="flex space-x-2 md:space-x-4 text-white font-inter font-normal text-sm justify-center md:justify-start">
                <div>
                  <MdAccessTimeFilled size={20} className="text-white" />
                </div>
                <span>8 AM - 5 PM , Monday - Saturday</span>
              </div> */}
            </div>
          </div>

          <div className="col-span-3 lg:col-span-3">
            <h2 className="capitalize text-white font-inter font-semibold text-xl mb-6 md:mb-10 text-center md:text-left">
              Newsletter
            </h2>

            <div className="space-y-5">
              <span className="text-white font-inter font-semibold text-base text-center md:text-left">
                Subscribe to our newsletter
              </span>

              <p className="text-white font-inter font-normal text-sm text-center md:text-left">
                Stay informed and never miss out on the latest news, health
                tips.
              </p>

              <div className="">
                <ToastContainer />
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  <Form className="lg:flex flex-col md:flex-row gap-2">
                    <div className="w-full">
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Enter Your Email"
                        className="w-full font-inter font-medium text-sm p-2 py-3 border bg-transparent border-white/75 rounded-md focus:outline-none placeholder:text-white/75 "
                      />
                      <ErrorMessage
                        name="email"
                        component="p"
                        className="mt-1 text-xs text-white"
                      />
                    </div>

                    <button
                      type="submit"
                      className="bg-transparent font-inter text-base font-semibold hover:bg-white text-white border-white rounded-md hover:text-primary py-2 px-4 border-2 focus:outline-none transition duration-300 ease-in-out flex items-center gap-2 mt-2 lg:mt-0"
                      disabled={saving} // Disable the button while saving
                    >
                      {saving ? "Sending..." : "Send"} <FaCircleArrowRight />
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="fixed space-y-2 bottom-3 right-6 lg:space-y-3">
        <div className="space-y-2 lg:flex lg:gap-3 lg:space-y-0">
          <FaArrowUp
            className="bg-[#e55353] rounded-full w-10 h-10 text-white px-3 cursor-pointer"
            onClick={handleScrollToTop}
            title="Scroll to Top"
          />
          {/* <MdOutlineQuiz
            className='bg-[#ebc774] rounded-full w-10 h-10 text-white px-2'
            title='Quiz'
            onMouseEnter={() => handleIconHover("Quiz icon")}
          /> */}
          {/* <BiMessageRoundedDots
            className='w-10 h-10 px-2 text-white bg-yellow-500 rounded-full'
            title='Bot'
            onMouseEnter={() => handleIconHover("Bot icon")}
          /> */}
          <MdSupportAgent
            className="bg-[#6bea3d] rounded-full w-10 h-10 text-white px-2 cursor-pointer"
            title="Support Agent"
            onMouseEnter={() => handleIconHover("Support Agent icon")}
            onClick={() => setIsChatOn(true)}
          />

          {isChatOn && <MainChatBot setIsChatOn={setIsChatOn} />}
        </div>
      </div>
      
    </>
  );
};

export default NewFooter;
