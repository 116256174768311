import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Banner from "../Utils/Banner";

function UnderstandingDiabetes() {
  return (
    <>
      <div
        className="bg-contain bg-no-repeat bg-gray-200 "
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className="mx-auto ">
          {/* <Hero /> */}
          {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
            UNDERSTANDING DIABETES
          </h1> */}
          <Banner
            title="UNDERSTANDING DIABETES"
            imageUrl="/assets/Second_Banner.png"
          />
          <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32">
            {/* <MythsFacts /> */}
            {/* <HeartDisease /> */}

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col mb-5 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div data-aos="fade-left" className=" bg-white w-full md:w-full">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center ">
                  Introduction
                </h1>
                <p
                  // data-aos="fade-up"
                  className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
                >
                  Diabetes mellitus is a chronic condition that results from an
                  imbalance in insulin production or utilization, affecting the
                  body's ability to convert food into energy. After consuming
                  food, it is transformed into glucose, the main energy source,
                  which is subsequently released into the bloodstream. In
                  response to elevated blood sugar levels, the pancreas releases
                  insulin, acting like a key to unlock cells, allowing glucose
                  to enter inside the cell and be used as energy. Insufficient
                  insulin production or its inefficient utilization can lead to
                  increased blood glucose levels (hyperglycemia). This situation
                  can potentially harm tiny nerves and blood vessels, impacting
                  essential organs like the heart, eyes, kidneys, brain, and
                  feet.
                </p>
              </div>

              <div
                // data-aos="fade-left"
                className="w-full flex md:w-full overflow-hidden justify-center"
              >
                <img
                  src="../assets/understaning-diabetes.jpg"
                  alt="Heart"
                  className="w-full lg:w-2/3 h-full object-contain"
                />
              </div>
            </section>

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col md:flex-row mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div
                className=" bg-white w-full md:w-full p-4"
                // data-aos="fade-left"
              >
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-2\0 ">
                  Our journey to understand Diabetes begins here
                </h1>
                <p
                  className="text-gray-600 xl:text-md text-left lg:text-lg md:text-md sm:p-4 lg:-ml-4 lg:-mr-10"
                  // data-aos="fade-up"
                >
                  Diabetes is a complicated and common medical condition that
                  has an impact on how your body controls blood sugar, a crucial
                  source of energy for daily activities. <br />
                  It is important to know about Prediabetes and three primary
                  types of diabetes, which includes type 1 diabetes, type 2
                  diabetes, and gestational diabetes.
                  <p className="mt-2">
                    <strong>Prediabetes  </strong>is a condition characterized
                    by blood sugar levels that are higher than normal but not
                    yet high enough to be classified as diabetes. However, it is
                    possible to stop or delay the development of diabetes with
                    lifestyle modifications including healthy eating and
                    frequent exercise.
                    <br />
                    <strong>Type 1 diabetes:</strong> This type is characterized
                    by the body's inability to produce insulin. It often
                    develops during childhood or early adulthood and
                    necessitates lifelong insulin therapy.
                    <br />
                    <strong>Type 2 diabetes:</strong> This is the most common
                    form of diabetes, and usually develops in adulthood due to
                    insulin resistance and inadequate insulin production.
                    <br />
                    <strong> Gestational diabetes:</strong> This temporary form
                    of diabetes occurs during pregnancy and increases the risk
                    of type 2 diabetes later in life.
                  </p>
                </p>
              </div>

              <div
                className="w-full flex md:w-full overflow-hidden"
                // data-aos="fade-up"
              >
                <img
                  src="../assets/ud-2.png"
                  alt="Heart"
                  className="w-full h-full object-contain"
                />
              </div>
            </section>

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col-reverse mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div
                // data-aos="fade-left"
                className="w-full flex md:w-full overflow-hidden justify-center"
              >
                <img
                  src="../assets/ud-3-new.png"
                  alt="Heart"
                  className="w-full lg:w-2/3 h-full object-contain rounded-lg"
                />
              </div>
              <div className=" bg-white w-full md:w-full ">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center">
                  Understanding symptoms
                </h1>
                <p
                  className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
                  // data-aos="fade-up"
                >
                  Recognizing the symptoms of diabetes can facilitate early
                  detection and successful control. The initial hints often
                  manifest as a combination of symptoms that indicate the body's
                  challenge in maintaining balanced blood sugar levels.
                  <p className="mt-5">
                    Symptoms of diabetes can vary depending on the type of
                    diabetes and how high your blood sugar is some common
                    symptoms are feeling very thirsty, urinating more often than
                    usual, blurred vision, feeling tired, losing weight without
                    trying.
                  </p>
                </p>
              </div>
            </section>

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col mb-5 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div className=" bg-white w-full md:w-full">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
                  Understanding risk factors
                </h1>
                <p
                  // data-aos="fade-up"
                  className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
                >
                  By understanding the risk factors, we gain insights into the
                  potential triggers of diabetes and can take proactive steps to
                  prevent or manage the condition.
                </p>
              </div>

              <div
                // data-aos="fade-up"
                className="w-full flex md:w-full overflow-hidden justify-center"
              >
                <img
                  src="../assets/ud-4.jpg"
                  alt="Heart"
                  className="w-full lg:w-2/3 h-full object-contain"
                />
              </div>
            </section>

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col-reverse mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div className="w-full flex md:w-full overflow-hidden justify-center">
                <img
                  src="../assets/ud-5.png"
                  alt="Heart"
                  className="w-full lg:w-2/3 h-full object-contain"
                />
              </div>
              <div className=" bg-white w-full md:w-full">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center">
                  Potential complications
                </h1>
                <p className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10">
                  Over time, inadequately managed diabetes can lead to a variety
                  of complications that affect different facets of health.
                  Understanding these complications is essential for individuals
                  with diabetes and their medical practitioners, as it enables
                  proactive steps to be taken to prevent the potential risks.
                </p>
              </div>
            </section>

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col md:flex-row mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div className="">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center">
                Other Causes of Diabetes
                </h1>
                <p
                  // data-aos="fade-up"
                  className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
                >
                  In addition to type 1, type 2, and gestational diabetes, other
                  less common types of diabetes affect a small proportion of
                  individuals. These include:
                  <div className="p-8 ">

                    {/* <ul className="list-none">
                      <li className="flex items-end mb-2">
                        <span className="bg-blue-500 h-3 w-3 rounded-full mr-5"></span>

                        <p>
                          Diabetes can also be caused by specific changes in
                          genes. For instance, there is a type of diabetes that
                          begins in adulthood known as ‘maturity-onset diabetes
                          of the young, and another that develops in newborns
                          called ‘neonatal diabetes’.
                        </p>
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="bg-green-500 h-3 w-3 rounded-full mr-5"></span>
                        Problem of pancreas like fibrocalculus pancreatic
                        diabetes, cystic fibrosis and trauma to the pancreas can
                        disrupt pancreas function and cause diabetes.
                      </li>

                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Certain drugs or chemicals, like glucocorticoids,
                        HIV/AIDS medications or after organ transplants, can
                        also trigger diabetes.
                      </li>

                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Due to the rarity of the condition, many types of
                        diabetes often go undiagnosed as other forms of the
                        disease. Every individual type needs to be treated based
                        on the cause for effective treatment outcomes. It is
                        important to seek immediate medical attention if any of
                        these types of diabetes are suspected.
                      </li>
                    </ul> */}
                    <span className="font-bold">1.</span> Diabetes can also be caused by specific changes in
                          genes. For instance, there is a type of diabetes that
                          begins in adulthood known as ‘maturity-onset diabetes
                          of the young, and another that develops in newborns
                          called ‘neonatal diabetes’.
                          <br/>
                          <br/>

                          <span className="font-bold">2.</span>  Problem of pancreas like fibrocalculus pancreatic
                        diabetes, cystic fibrosis and trauma to the pancreas can
                        disrupt pancreas function and cause diabetes. 
                        <br/>   
                        <br/>   

                        <span className="font-bold">3.</span> Certain drugs or chemicals, like glucocorticoids,
                        HIV/AIDS medications or after organ transplants, can
                        also trigger diabetes.
                        <br/>
                        <br/>
                        <span className="font-bold">4.</span> Due to the rarity of the condition, many types of
                        diabetes often go undiagnosed as other forms of the
                        disease. Every individual type needs to be treated based
                        on the cause for effective treatment outcomes. It is
                        important to seek immediate medical attention if any of
                        these types of diabetes are suspected.
                        <br/>
                        <br/>

                    <p className="mt-3">
                      Due to the rarity of the condition, many types of diabetes
                      often go undiagnosed as other forms of the disease. Every
                      individual type needs to be treated based on the cause for
                      effective treatment outcomes. It is important to seek
                      immediate medical attention if any of these types of
                      diabetes are suspected.
                    </p>
                    <h3 className="font-bold mt-5 text-center text-teal-500 text-xl mb-4">
                     Diagnosing Diabetes
                    </h3>
                    <p>
                    The specific tests recommended by the healthcare provider may depend on your symptoms, risk factors, and the type of diabetes suspected.
                    </p>
                    <div className=" flex justify-center">
                      <img
                        src="../assets/diagnosis.jpeg"
                        alt="Heart"
                        className="w-full lg:w-2/3 object-contain mt-5 rounded-lg"
                      />
                    </div>
                  </div>
                </p>
              </div>
            </section>

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col md:flex-row mb-4 lg:p-20 md:p-10 gap-4 p-8 bg-white lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div className="">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
                  Diabetes prevention and management
                </h1>
                <p className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10">
                  Diabetes management often involves regular monitoring of blood
                  sugar levels, routine check-ups, and proper education to help
                  individuals make informed choices about their health.
                  <br />
                  Adopting proper management strategies and medical supervision
                  can help people with diabetes lead happy and healthy lives.
                  Working closely with healthcare professionals is important to
                  develop an ideal management strategy.
                  <br />
                  Daily self-care helps you make judicious decisions about
                  balanced and nourishing diet, regular physical activity,
                  consistent monitoring of blood sugar levels, and strict
                  adherence to medication guidelines.
                  <br />
                  Taking care of yourself not only keeps you healthy, but also
                  lowers the chances of developing conditions like heart
                  disease, kidney disease, nerve damage, eye problems, dental
                  issues, and more.
                  <div className="p-8">
                    {/* <ul className="list-none md:list-disc">
                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Healthy eating: Adopting a balanced diet with controlled
                        portions of carbohydrates, proteins, and fats can help
                        regulate blood sugar levels effectively.
                      </li>

                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Regular exercise: Engaging in physical activity improves
                        insulin sensitivity, promotes weight management, and
                        lowers blood sugar levels.
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Medication: Insulin injections or oral medications may
                        be prescribed to manage the sugar levels effectively
                        based on the type of diabetes and individual needs.
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Blood sugar monitoring: Regular monitoring helps to
                        track glucose levels and make necessary adjustments to
                        medication or lifestyle.
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Stress management: Stress can affect blood sugar levels,
                        so adopting stress-reducing techniques is crucial to
                        maintain optimal sugar levels.
                      </li>
                      <li className="flex items-center mb-2">
                        <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
                        Regular check-ups: Routine medical appointments allow
                        for comprehensive Diabetes management and early
                        detection of complications.
                      </li>
                    </ul> */}
                     <span className="font-bold">1.</span> Healthy eating: Adopting a balanced diet with controlled
                        portions of carbohydrates, proteins, and fats can help
                        regulate blood sugar levels effectively.
                          <br/>
                          <br/>

                          <span className="font-bold">2.</span> Regular exercise: Engaging in physical activity improves
                        insulin sensitivity, promotes weight management, and
                        lowers blood sugar levels.
                          <br/>
                          <br/>

                          <span className="font-bold">3.</span> Medication: Insulin injections or oral medications may
                        be prescribed to manage the sugar levels effectively
                        based on the type of diabetes and individual needs.
                          <br/>
                          <br/>
                          <span className="font-bold">4.</span> Blood sugar monitoring: Regular monitoring helps to
                        track glucose levels and make necessary adjustments to
                        medication or lifestyle.
                          <br/>
                          <br/>
                          <span className="font-bold">5.</span> Stress management: Stress can affect blood sugar levels,
                        so adopting stress-reducing techniques is crucial to
                        maintain optimal sugar levels.
                          <br/>
                          <br/>
                          <span className="font-bold">6.</span> Regular check-ups: Routine medical appointments allow
                        for comprehensive Diabetes management and early
                        detection of complications.
                          <br/>
                          <br/>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <img
                      src="../assets/management.jpeg"
                      alt="Heart"
                      className="w-full lg:w-2/3 object-contain mt-10 rounded-lg"
                    />
                    <img
                      src="../assets/ud-8.jpg"
                      alt="Heart"
                      className="w-full lg:w-2/3 object-contain mt-10 rounded-lg"
                    />
                  </div>
                </p>
              </div>
            </section>

            <section
              data-aos="fade-left"
              className="mt-20 flex flex-col mb-5 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div className="w-full md:w-full ">
                <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
                  Treatment options for Diabetes
                </h1>
                <p className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10">
                  The treatment for diabetes depends on the type of diabetes and
                  its severity. For type 1 diabetes, insulin therapy is
                  essential as the body does not produce insulin. Type 2
                  diabetes can often be controlled through lifestyle
                  modifications, including diet, exercise, weight management,
                  and sometimes oral medications or insulin. Gestational
                  diabetes during pregnancy may be managed with diet, exercise,
                  blood sugar monitoring, and, if necessary, insulin.
                </p>
              </div>

              <div className="w-full flex justify-center md:w-full overflow-hidden">
                <img
                  src="../assets/ud-9.png"
                  alt="Heart"
                  className="w-full lg:w-2/3 h-full object-contain"
                />
              </div>
            </section>

            <section
              className="mt-20 flex flex-col md:flex-row lg:p-20 md:p-10 gap-4 p-8 bg-white mb-5 lg:-ml-20 lg:-mr-20"
              style={{ marginTop: 40 }}
            >
              <div className="">
                {/* <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
                  Exercises for Diabetes
                </h1> */}

                <div className="w-full flex justify-center md:w-full overflow-hidden">
                <img
                  src="../assets/Exercises for Diabetics.png"
                  alt="Heart"
                  className="w-full lg:w-2/3 h-full object-contain mb-4"
                />
              </div>


                <p className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10">
                  There are various types of exercises that can help manage
                  Diabetes by improving insulin sensitivity, promoting weight
                  management, and enhancing overall health.
                  <p className="p-2">
                    <strong>1. Aerobic (cardiovascular) exercises </strong> :
                    Brisk walking, cycling, swimming, dancing and running <br />
                    <strong>
                      2. Strength training (resistance) exercises{" "}
                    </strong>{" "}
                    : Weightlifting and body weight exercises <br />
                    <strong>3. Flexibility and balance exercises </strong> :
                    Yoga and Tai Chi <br />
                    <strong>4. High-intensity interval training </strong> :
                    Short bursts of intense exercise followed by brief periods
                    of rest <br />
                    <h3 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mt-5 mb-4 text-center">
                    Regular screening and follow-up
                    </h3>
                    <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg text-justify">
                    Regular screening and follow-up for diabetes are crucial for early detection and effective management of the condition. It also serves as a preventive tool, particularly if the disease runs in the hereditary. A person who has these screens can learn their current blood pressure, insulin, and sugar levels, as well as whether they are prediabetic (at a very high risk of developing diabetes). Depending on the risk profile, the clinician may recommend periodic blood sugar tests. The frequency of testing will vary based on your risk and current health status.
                    </p>
                    <h3 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mt-5 mb-4 text-center">
                      Conclusion{" "}
                    </h3>
                    <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg text-justify">
                      Although diabetes necessitates lifelong management,
                      grasping its types, symptoms, and efficient control
                      strategies can notably enhance the well-being of those
                      impacted. Embracing a wholesome lifestyle, vigilant
                      monitoring of blood sugar, and maintaining a strong
                      partnership with healthcare experts empower individuals
                      with diabetes to enjoy rewarding lives while curbing
                      complications. Initiating proactive measures to address
                      this worldwide health concern holds pivotal significance
                      in alleviating the burden of diabetes.
                    </p>
                    <p className="text-[#2d95a5] bg-blue-100 rounded-md p-4 mt-10 shadow-lg font-ubuntu lg:text-xl text-justify">
                      “Every day is an absolute battle. I don’t care what anyone
                      says. You have to wake up and say to yourself, ‘I accept
                      that I have diabetes, and I’m not going to let it run my
                      entire life.” – Bret Michaels{" "}
                    </p>
                  </p>
                </p>
              </div>
            </section>

            {/* <BlogAndArticle /> */}
            {/* <Ads />
            <Subscribe /> */}
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default UnderstandingDiabetes;







// import NewNavbar from "../Components/Common/NewNavbar";
// import NewFooter from "../Components/Common/NewFooter";
// import Copyright from "../Components/FirstComponents/Copyright";
// import Banner from "../Utils/Banner";

// function UnderstandingDiabetes() {
//   return (
//     <>
//       <div
//         className="bg-contain bg-no-repeat bg-gray-200 "
//         style={{
//           backgroundImage: "url('/assets/background.png')",
//           backgroundPosition: "right top",
//         }}
//       >
//         <NewNavbar />
//         <div className="mx-auto ">
//           {/* <Hero /> */}
//           {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
//             UNDERSTANDING DIABETES
//           </h1> */}
//           <Banner
//             title="UNDERSTANDING DIABETES"
//             imageUrl="/assets/Second_Banner.png"
//           />
//           <div className="mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32">
//             {/* <MythsFacts /> */}
//             {/* <HeartDisease /> */}

//             <section
//               data-aos="fade-left"
//               className="mt-20 flex flex-col mb-5 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div data-aos="fade-left" className=" bg-white w-full md:w-full">
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center ">
//                 Introduction
//                 </h1>
//                 <p
//                   // data-aos="fade-up"
//                   className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
//                 >

//                   Diabetes mellitus is a chronic condition that results from an imbalance in insulin production or utilization,
//                   affecting the body's ability to convert food into energy. After consuming food, it is transformed into glucose,
//                    the main energy source, which is subsequently released into the bloodstream. In response to elevated blood
//                    sugar levels, the pancreas releases insulin, acting like a key to unlock cells, allowing glucose to enter
//                     inside the cell and be used as energy. Insufficient insulin production or its inefficient utilization can
//                     lead to increased blood glucose levels (hyperglycemia). This situation can potentially harm tiny nerves
//                     and blood vessels, impacting essential organs like the heart, eyes, kidneys, brain, and feet.

//                 </p>
//               </div>

//               <div
//                 // data-aos="fade-left"
//                 className="w-full flex md:w-full overflow-hidden justify-center"
//               >
//                 <img
//                   src="../assets/understaning-diabetes.jpg"
//                   alt="Heart"
//                   className="w-full lg:w-2/3 h-full object-contain"
//                 />
//               </div>
//             </section>

//             <section
//               data-aos="fade-left"
//               className="mt-20 flex flex-col md:flex-row mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div
//                 className=" bg-white w-full md:w-full p-4"
//                 // data-aos="fade-left"
//               >
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 ">
//                   Our journey to understand Diabetes begins here
//                 </h1>
//                 <p
//                   className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
//                   // data-aos="fade-up"
//                 >
//                   Diabetes is a complicated and common medical condition that has an impact on how your body controls blood
//                   sugar, a crucial source of energy for daily activities. <br />
//                   It is important to know about Prediabetes and three primary types of diabetes, which includes type 1 diabetes, type 2 diabetes, and gestational diabetes.

//                   <p className="mt-2">
//                     <strong>Prediabetes  </strong>is a condition characterized by blood sugar levels that are higher than normal but not yet high enough to be classified as diabetes. However, it is possible to stop or delay the development of diabetes with lifestyle modifications including healthy eating and frequent exercise.
//                     <br />
//                     <strong>Type 1 diabetes:</strong> This type is characterized by the body's inability to produce insulin. It often develops during childhood or early adulthood and necessitates lifelong insulin therapy.
//                     <br />
//                     <strong>Type 2 diabetes:</strong> This is the most common form of diabetes, and usually develops in adulthood due to insulin resistance and inadequate insulin production.
//                     <br />
//                    <strong> Gestational diabetes:</strong> This temporary form of diabetes occurs during pregnancy and increases the risk of type 2 diabetes later in life.

//                   </p>
//                 </p>
//               </div>

//               <div
//                 className="w-full flex md:w-full overflow-hidden"
//                 // data-aos="fade-up"
//               >
//                 <img
//                   src="../assets/ud-2.png"
//                   alt="Heart"
//                   className="w-full h-full object-contain"
//                 />
//               </div>
//             </section>

//             <section
//               data-aos="fade-left"
//               className="mt-20 flex flex-col-reverse mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div
//                 // data-aos="fade-left"
//                 className="w-full flex md:w-full overflow-hidden justify-center"
//               >
//                 <img
//                   src="../assets/ud-3.png"
//                   alt="Heart"
//                   className="w-full lg:w-2/3 h-full object-contain"
//                 />
//               </div>
//               <div className=" bg-white w-full md:w-full ">
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center">
//                   Understanding symptoms
//                 </h1>
//                 <p
//                   className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
//                   // data-aos="fade-up"
//                 >
//                  Recognizing the symptoms of diabetes can facilitate early detection and successful control. The initial hints often manifest as a combination of symptoms that indicate the body's challenge in maintaining balanced blood sugar levels.

//                   <p className="mt-5" >
//                   Symptoms of diabetes can vary depending on the type of diabetes and how high your blood sugar is some Common symptoms are feeling very thirsty, urinating more often than usual, blurred vision, feeling tired, losing weight without trying.

//                   </p>
//                 </p>
//               </div>
//             </section>

//             <section
//               data-aos="fade-left"
//               className="mt-20 flex flex-col mb-5 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div className=" bg-white w-full md:w-full">
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
//                   Understanding risk factors
//                 </h1>
//                 <p
//                   // data-aos="fade-up"
//                   className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
//                 >
//                   By understanding the risk factors, we gain insights into the
//                   potential triggers of diabetes and can take proactive steps to
//                   prevent or manage the condition.
//                 </p>
//               </div>

//               <div
//                 // data-aos="fade-up"
//                 className="w-full flex md:w-full overflow-hidden justify-center"
//               >
//                 <img
//                   src="../assets/ud-4.jpg"
//                   alt="Heart"
//                   className="w-full lg:w-2/3 h-full object-contain"
//                 />
//               </div>
//             </section>

//             <section
//               data-aos="fade-left"
//               className="mt-20 flex flex-col-reverse mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div

//                 className="w-full flex md:w-full overflow-hidden justify-center"
//               >
//                 <img

//                   src="../assets/ud-5.png"
//                   alt="Heart"
//                   className="w-full lg:w-2/3 h-full object-contain"
//                 />
//               </div>
//               <div className=" bg-white w-full md:w-full">
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center">
//                   Potential complications
//                 </h1>
//                 <p

//                   className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
//                 >
//                   Over time, inadequately managed diabetes can lead to a variety
//                   of complications that affect different facets of health.
//                   Understanding these complications is essential for individuals
//                   with diabetes and their medical practitioners, as it enables
//                   proactive steps to be taken to prevent the potential risks.
//                 </p>
//               </div>
//             </section>

//             <section
//              data-aos="fade-left"
//               className="mt-20 flex flex-col md:flex-row mb-4 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div className="">
//                 <h1

//                   className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center"
//                 >
//                   Educating oneself on other causes of Diabetes{" "}
//                 </h1>
//                 <p
//                   // data-aos="fade-up"
//                   className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10"
//                 >
//                   In addition to type 1, type 2, and gestational diabetes, other
//                   less common types of diabetes affect a small proportion of
//                   individuals. These include:
//                   <div className="p-8">
//                     <ul className="list-none">
//                       <li className="flex items-center mb-2">
//                         <span className="bg-blue-500 h-3 w-3 rounded-full mr-5"></span>

//                         <p>
//                         Diabetes can also be caused by specific changes in genes. For instance, there is a type of diabetes that begins in adulthood known as ‘maturity-onset diabetes of the young, and another that develops in newborns called ‘neonatal diabetes’.
//                         </p>
//                       </li>
//                       <li className="flex items-center mb-2">
//                         <span className="bg-green-500 h-3 w-3 rounded-full mr-5"></span>
//                         Problem of pancreas like fibrocalculus pancreatic diabetes, cystic fibrosis and trauma to the pancreas can disrupt pancreas function and cause diabetes.
//                       </li>

//                       <li className="flex items-center mb-2">
//                         <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                         Certain drugs or chemicals, like glucocorticoids, HIV/AIDS medications or after organ transplants, can also trigger diabetes.
//                       </li>

//                       <li className="flex items-center mb-2">
//                         <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                         Due to the rarity of the condition, many types of diabetes often go undiagnosed as other forms of the disease. Every individual type needs to be treated based on the cause for effective treatment outcomes. It is important to seek immediate medical attention if any of these types of diabetes are suspected.
//                       </li>

//                     </ul>

//                     <p className="mt-3">
//                       Due to the rarity of the condition, many types of diabetes
//                       often go undiagnosed as other forms of the disease. Every
//                       individual type needs to be treated based on the cause for
//                       effective treatment outcomes. It is important to seek
//                       immediate medical attention if any of these types of
//                       diabetes are suspected.
//                     </p>
//                     <h3 className="font-bold mt-5 text-center text-teal-500 text-xl mb-4">
//                       Diagnosing diabetes
//                     </h3>
//                     <p>
//                       The specific tests recommended by the healthcare provider
//                       may depend on your symptoms, risk factors, and the type of
//                       diabetes suspected.
//                     </p>
//                     <div className=" flex justify-center">
//                       <img
//                         src="../assets/ud-6.jpg"
//                         alt="Heart"
//                         className="w-full lg:w-2/3 object-contain mt-5 rounded-lg"
//                       />
//                     </div>
//                   </div>
//                 </p>
//               </div>
//             </section>

//             <section
//               data-aos="fade-left"
//               className="mt-20 flex flex-col md:flex-row mb-4 lg:p-20 md:p-10 gap-4 p-8 bg-white lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div className="">
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
//                   Diabetes prevention and management
//                 </h1>
//                 <p className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10">
//                 Diabetes management often involves regular monitoring of blood sugar levels, routine check-ups, and proper education to help individuals make informed choices about their health.
//                 <br />
//                 Adopting proper management strategies and medical supervision can help people with diabetes lead happy and healthy lives. Working closely with healthcare professionals is important to develop an ideal management strategy.
//                 <br />
//                 Daily self-care helps you make judicious decisions about balanced and nourishing diet, regular physical activity, consistent monitoring of blood sugar levels, and strict adherence to medication guidelines.
//                 <br />
//                 Taking care of yourself not only keeps you healthy, but also lowers the chances of developing conditions like heart disease, kidney disease, nerve damage, eye problems, dental issues, and more.

//                 <div className="p-8">

//                 <ul className="list-none md:list-disc">

//                 <li className="flex items-center mb-2">
//                       <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                       Healthy eating: Adopting a balanced diet with controlled portions of carbohydrates, proteins, and fats can help regulate blood sugar levels effectively.
//                 </li>

//                 <li className="flex items-center mb-2">
//                       <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                       Regular exercise: Engaging in physical activity improves insulin sensitivity, promotes weight management, and lowers blood sugar levels.
//                 </li>
//                 <li className="flex items-center mb-2">
//                       <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                       Medication: Insulin injections or oral medications may be prescribed to manage the sugar levels effectively based on the type of diabetes and individual needs.
//                 </li>
//                 <li className="flex items-center mb-2">
//                       <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                       Blood sugar monitoring: Regular monitoring helps to track glucose levels and make necessary adjustments to medication or lifestyle.
//                 </li>
//                 <li className="flex items-center mb-2">
//                       <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                       Stress management: Stress can affect blood sugar levels, so adopting stress-reducing techniques is crucial to maintain optimal sugar levels.
//                 </li>
//                 <li className="flex items-center mb-2">
//                       <span className="bg-yellow-500 h-3 w-3 rounded-full mr-5"></span>
//                       Regular check-ups: Routine medical appointments allow for comprehensive
//                   Diabetes management and early detection of complications.
//                 </li>

//                 </ul>

//                   </div>

//                   <div className="flex flex-col justify-center items-center">
//                     <img
//                       src="../assets/ud-7.png"
//                       alt="Heart"
//                       className="w-full lg:w-2/3 object-contain mt-10 rounded-lg"
//                     />
//                     <img
//                       src="../assets/ud-8.jpg"
//                       alt="Heart"
//                       className="w-full lg:w-2/3 object-contain mt-10 rounded-lg"
//                     />
//                   </div>
//                 </p>
//               </div>
//             </section>

//             <section
//              data-aos="fade-left"
//               className="mt-20 flex flex-col mb-5 bg-white lg:p-20 md:p-10 gap-4 p-8 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div className="w-full md:w-full ">
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
//                   Treatment options for Diabetes
//                 </h1>
//                 <p className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10">
//                   The treatment for diabetes depends on the type of diabetes and
//                   its severity. For type 1 diabetes, insulin therapy is
//                   essential as the body does not produce insulin. Type 2
//                   diabetes can often be controlled through lifestyle
//                   modifications, including diet, exercise, weight management,
//                   and sometimes oral medications or insulin. Gestational
//                   diabetes during pregnancy may be managed with diet, exercise,
//                   blood sugar monitoring, and, if necessary, insulin.
//                 </p>
//               </div>

//               <div className="w-full flex justify-center md:w-full overflow-hidden">
//                 <img
//                   src="../assets/ud-9.png"
//                   alt="Heart"
//                   className="w-full lg:w-2/3 h-full object-contain"
//                 />
//               </div>
//             </section>

//             <section
//               className="mt-20 flex flex-col md:flex-row lg:p-20 md:p-10 gap-4 p-8 bg-white mb-5 lg:-ml-20 lg:-mr-20"
//               style={{ marginTop: 40 }}
//             >
//               <div className="">
//                 <h1 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center">
//                 Exercises for Diabetes
//                 </h1>
//                 <p className="text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10">
//                   There are various types of exercises that can help manage
//                   Diabetes by improving insulin sensitivity, promoting weight
//                   management, and enhancing overall health.
//                   <p className="p-2">
//                     <strong>1. Aerobic (cardiovascular) exercises </strong> :
//                     Brisk walking, cycling, swimming, dancing and running <br />
//                     <strong>
//                       2. Strength training (resistance) exercises{" "}
//                     </strong>{" "}
//                     : Weightlifting and body weight exercises <br />
//                     <strong>3. Flexibility and balance exercises </strong> :
//                     Yoga and Tai Chi <br />
//                     <strong>4. High-intensity interval training </strong> :
//                     Short bursts of intense exercise followed by brief periods
//                     of rest <br />
//                     <h3 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mt-5 mb-4 text-center">
//                       Regular screening and follow-up
//                     </h3>
//                     <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg text-justify">
//                       Regular screening and follow-up for diabetes are crucial
//                       for early detection and effective management of the
//                       condition. It also serves as a preventative tool,
//                       particularly if the disease runs in the family. A person
//                       who has these screens can learn their current blood
//                       pressure, insulin, and sugar levels, as well as whether
//                       they are prediabetic (at a very high risk of developing
//                       diabetes). Depending on the risk profile, the clinician
//                       may recommend periodic blood sugar tests. These tests can
//                       include fasting plasma glucose, oral glucose tolerance
//                       test, and hemoglobin A1c (HbA1c) tests. The frequency of
//                       testing will vary based on your risk and current health
//                       status.
//                     </p>
//                     <h3 className="text-[#2d95a5] font-semibold text-xl lg:text-3xl mt-5 mb-4 text-center">
//                       Conclusion{" "}
//                     </h3>
//                     <p className="bg-blue-100 rounded-md p-4 mt-5 shadow-lg text-justify">
//                       Although diabetes necessitates lifelong management,
//                       grasping its types, symptoms, and efficient control
//                       strategies can notably enhance the well-being of those
//                       impacted. Embracing a wholesome lifestyle, vigilant
//                       monitoring of blood sugar, and maintaining a strong
//                       partnership with healthcare experts empower individuals
//                       with diabetes to enjoy rewarding lives while curbing
//                       complications. Initiating proactive measures to address
//                       this worldwide health concern holds pivotal significance
//                       in alleviating the burden of diabetes.
//                     </p>
//                     <p className="text-[#2d95a5] bg-blue-100 rounded-md p-4 mt-10 shadow-lg font-ubuntu lg:text-xl text-justify">
//                       “Every day is an absolute battle. I don’t care what anyone
//                       says. You have to wake up and say to yourself, ‘I accept
//                       that I have diabetes, and I’m not going to let it run my
//                       entire life.” – Bret Michaels{" "}
//                     </p>
//                   </p>
//                 </p>
//               </div>
//             </section>

//             {/* <BlogAndArticle /> */}
//             {/* <Ads />
//             <Subscribe /> */}
//           </div>
//           <NewFooter />
//           <Copyright />
//         </div>
//       </div>
//     </>
//   );
// }

// export default UnderstandingDiabetes;

