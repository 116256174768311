import React from "react";

const OrgansAffected = () => {
  return (
    <section className='overflow-hidden  flex flex-col items-center lg:-mt-30 mb-16'>
      
      <h1
        className='text-2xl lg:text-4xl font-inter font-semibold text-textColor p-6 lg:p-0 mb-5'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='300'
        crossOrigin='anonymous'
      >
        Organs affected by Diabetes
      </h1>
      {/* <img
        src="/assets/New-Hero/Organs-Image.png"
        alt="organs-affected"
        className="w-7/12"
      /> */}

      {/* <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 w-full max-w-6xl mt-10 m-5">
        <div className="flex justify-center items-center">
          <div className="bg-white rounded-xl overflow-hidden w-full max-w-[500px] h-[300px] md:h-[400px] lg:h-[500px]">
            <img
              src="/assets/New-Hero/organ.png"
              alt="organs-affected"
              className="w-full h-full object-contain"
            />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <div className="bg-white rounded-xl overflow-hidden w-full max-w-[500px] h-[300px] md:h-[400px] lg:h-[500px]">
            <video
              className="w-full h-full object-contain"
              controls
              autoPlay
              loop
              muted
              playsInline
            >
              <source src="/assets/New-Hero/human_body.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div> */}

      <div
        className='space-y-6 mt'
        data-aos='fade-left'
        data-aos-easing='linear'
        data-aos-duration='300'
        crossOrigin='anonymous'
      >
        <div className='flex justify-center items-center'>
          <div className='bg-white rounded-xl overflow-hidden lg:w-3/6'>
            <img
              src='/assets/New-Hero/Liver.png'
              alt='organs-affected'
              className='w-full h-full object-contain'
            />
          </div>
        </div>

        {/* <div className="flex justify-center items-center">
          <div className="bg-white rounded-xl overflow-hidden w-full">
            <video
              className="w-full h-full object-contain"
              controls
              autoPlay
              loop
              muted
              playsInline
            >
              <source src="/assets/New-Hero/human_body.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div> */}
      </div>
     
    </section>
  );
};

export default OrgansAffected;





// import React from "react";

// const OrgansAffected = () => {
//   return (
//     <section className='border border-gray-500 flex flex-col items-center lg:-mt-30 mb-16 overflow-hidden'>
//       <h1
//         className='text-2xl lg:text-4xl font-inter font-semibold text-textColor p-6 lg:p-0 mb-5'
//         data-aos='fade-left'
//         data-aos-easing='linear'
//         data-aos-duration='300'
//         crossOrigin='anonymous'
//       >
//         Organs affected by Diabetes
//       </h1>

//       <div
//         className='space-y-6 mt'
//         data-aos='fade-left'
//         data-aos-easing='linear'
//         data-aos-duration='300'
//         crossOrigin='anonymous'
//       >
//         <div className='flex justify-center items-center'>
//           <div className='bg-white rounded-xl overflow-hidden max-w-full lg:w-3/6'>
//             <img
//               src='/assets/New-Hero/Liver.png'
//               alt='organs-affected'
//               className='w-full h-auto object-contain max-w-full' // Adjusted to prevent overflow
//             />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default OrgansAffected;
