import { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Configuration, OpenAIApi } from "openai";

import axios from "axios";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import RenderTranslate from "./Translation";
import { MdSend, MdSettingsVoice } from "react-icons/md";
import "react-toastify/dist/ReactToastify.css";

const YOU = "you";
const AI = "ai";
function ChatBot({ user }) {
  const [translatedText, setTranslatedText] = useState("");
  const [translateState, setTranslateState] = useState(false);
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef([]);
  const [qna, setQna] = useState([]);
  const [loading, setLoading] = useState(false);

  const configuration = new Configuration({
    organizationId: process.env.ORGANIZATION_ID,
    apiKey: process.env.OPEN_AI_KEY,

  });

  const openai = new OpenAIApi(configuration);

  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "Enter") {
        handleSend();
      }
    });
    return () => {
      document.removeEventListener("keydown", () => {});
    };
  }, []);

  const updateQNA = (from, value) => {
    setQna((qna) => [...qna, { from, value }]);
  };

  const handleSend = () => {
    const sentence1 = inputRef.current.value;
    const sentence2 = `cardiovascular  metformin insulin type types sugar blood diabetes diabetic bp Retinopathy Periodontitis Neuropathy Nephropathy Monogenic Impaired glucose tolerance IGT Hypoglycaemia Hyperglycaemia Glycosylated haemoglobin A1c HbA1c Glycogen Glucose Glucagon Gestational diabetes mellitus GDM foot Beta cells cardio SGLTi
    Dapagliflozin 
    Heart
    Cardiovascular
    Hear failure
    Stroke
    Liver failure
    Empagliflozin
    Sitagliptin
    Vildagliptin
    Zucapride
    Pioglitazone
    Sulphonylurea
    Glimepiride
    Linagliptin
    Teneligliptin
    Tenepride
    Tripride
    Metformin
    Insulin
    Diabetes
    Insulin resistance
    CMD
    Liver
    Kidney
    Kidney Disease
    Dialysis`;
    inputRef.current.value = "";
    setInputValue("");
    setTranslatedText("");
    setTranslateState(false);
    const words1 = sentence1.split(" ");
    const words2 = sentence2.split(" ");

    const commonWords = words1.filter((word) => words2.includes(word));

    if (commonWords.length > 0) {
      var question = " ";
      question = sentence1;
      if (question === "") {
        return toast("Please Write Something!");
      }

      // const resetInputField = () => {
      //   setQna("");
      // };

      updateQNA(YOU, question);
      setLoading(true);
      axios
        .post("http://localhost:3004/api/chat", {
          question,
        })
        .then((response) => {
          updateQNA(AI, response.data.answer);
          console.log("first", response.data.answer);
        })
        .finally(() => {
          setLoading(false);
          // resetInputField();
        });
    } else {
      toast("Ask something related to diabetes!");
    }
  };

  const renderContent = (qna) => {
    const value = qna.value;

    if (Array.isArray(value)) {
      return value.map((v) => <p className='message-text'>{v}</p>);
    }

    return <p className='message-text'>{value}</p>;
  };

  const startRecord = () => {
    SpeechRecognition.startListening();
  };

  const stopRecord = () => {
    SpeechRecognition.stopListening();
    // console.log('stop')
    console.log("SpeechRecognition",transcript);
    setInputValue(transcript);
  };

  const translate = async (language, translateText) => {
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      prompt: `Translate this into ${language}: ${translateText}`,
      temperature: 0.3,
      max_tokens: 4000,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
    });

    setTranslatedText(response.data.choices[0].text);
    console.log("Response",response);
    console.log("Translated Text",translateText);
  };

  return (
    <>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
      <main className='container'>
        <div className='chats'>
          <div className='recieve chat late_transition'>
            <img src='../assets/zuca.jpeg' alt='logo' className='avtar' />
            <p>Hii {user.name}, its great to see you 👋</p>
          </div>
          {qna.map((qna, index) => {
            if (qna.from === YOU) {
              return (
                <div className='send chat' key={index}>
                  {renderContent(qna)}
                </div>
              );
            }
            return (
              <>
                <div className='recieve chat' key={index}>
                  <img src='../assets/zuca.jpeg' alt='logo' className='avtar' />
                  {renderContent(qna)}
                </div>
                <p className='translate'>
                  Translate to{" "}
                  <span
                    onClick={() => {
                      setTranslateState("");
                      translate("hindi", qna.value[0]);
                      setTranslateState(true);
                    }}
                  >
                    Hindi
                  </span>
                  ,
                  <span
                    onClick={() => {
                      setTranslateState("");
                      translate("kannada", qna.value[0]);
                      setTranslateState(true);
                    }}
                  >
                    Kannada
                  </span>
                </p>
              </>
            );
          })}
          {translateState === true && (
            <RenderTranslate
              text={translatedText === "" ? "Loading....." : translatedText}
            />
          )}
          {loading && (
            <div className='recieve chat'>
              <img src='../assets/zuca.jpeg' alt='' className='avtar' />
              <p>Typing...</p>
            </div>
          )}
        </div>

        <div className='chat-input'>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <button
              onTouchStart={startRecord}
              onMouseDown={startRecord}
              onTouchEnd={stopRecord}
              onMouseUp={stopRecord}
            >
              {listening ? (
                <MdSettingsVoice fontSize='small' style={{ color: "red" }} />
              ) : (
                <MdSettingsVoice fontSize='small' style={{ color: "gray" }} />
              )}
            </button>
          </div>
          <input
            type='text'
            onChange={(e) => setInputValue(e.target.value)}
            value={inputValue}
            ref={inputRef}
            className='form-control col'
            placeholder='Type Something'
            id='ques'
          />
          <button
            disabled={loading}
            className='btn btn-success'
            onClick={() => {
              handleSend();
              resetTranscript();
            }}
          >
            <MdSend style={{ color: "gray", fontSize: "20px" }} />
          </button>
        </div>
      </main>
    </>
  );
}

export default ChatBot;
