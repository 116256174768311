
import NewNavbar from "../Components/Common/NewNavbar";
import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import Ads from "../Components/FirstComponents/Ads";
import Subscribe from "../Components/FirstComponents/Subscribe";
import Banner from "../Utils/Banner";

function UnderstandingHypertension() {
  return (
    <>
      <div
        className='bg-contain bg-no-repeat bg-gray-100 '
        style={{
          backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className='mx-auto '>
          {/* <Hero /> */}
          {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
            UNDERSTANDING ON HYPERTENSION & CVD
          </h1> */}

          <Banner
            title='UNDERSTANDING ON HYPERTENSION & CVD'
            imageUrl='/assets/Second_Banner.png'
          />

          <div className='mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32'>
            {/* <MythsFacts /> */}
            {/* <HeartDisease /> */}

            <section
              className='bg-white rounded-lg lg:p-20 md:p-10 gap-4 p-5 lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 0 }}
            >

              
                <strong>What is blood Pressure ? </strong> <br />
                
                Blood pressure is the measurement of the pressure or force of blood inside your arteries. Each time your heart beats, it pumps blood into arteries that carry blood throughout your body. This happens 60 to 100 times a minute, 24 hours a day. Arteries deliver oxygen and nutrients to your whole body so it can function.

                <p className="mb-4">
                  <ul className="mt-2 ml-10">
                    <li>1. 
                    Normal systolic BP is {"<"}120 millimetres of mercury (mm Hg), and normal diastolic blood pressure is {"<"}80 mm Hg, which is referred to as 120/80 mm Hg
                    </li>
                    <li>2. 
                    Elevated blood pressure is defined as systolic blood pressure consistently ranging from 120 to 129 mm Hg systolic and {"<"}80 mm Hg diastolic.
                    </li>
                    <li>3. BP is deemed high (Hypertension Stage 1) if it is 130 to 139 mm Hg systolic or 80 to 89 mm Hg diastolic.</li>
                    <li>4. Hypertension Stage 2 is defined as BP  {"≥"}140/90</li>
                    <li>5. A BP value {"≥"}180/120 mm Hg on multiple occasions indicates a hypertensive crisis and immediate medical attention should be sought.</li>
                          
                  </ul>
                </p>
                <strong>What blood pressure is too high ? </strong>
                <p className="mb-4">
                If your top number is ever 180 or higher and/or your bottom number is ever 120 or higher, get emergency medical treatment or have someone take you to the hospital right away. This is a hypertensive crisis during a hypertensive crisis, you may experience


                  <ul className="mt-2 ml-10"> 
                    <li>1. Shortness of breath</li>
                    <li>2. Chest Pain </li>
                    <li>3. Difficulty with being able to see or talk </li>
                    <li>4. Pain in your back </li>
                    <li>5. Weakness or numbness </li>
                    
                    
                  </ul>
                </p>

                <strong>What blood pressure is too low ? </strong>                
                <p className="mb-4">
               
                millimetres of mercury is a low blood pressure reading. This can be a normal reading for some people who always have low blood pressure. For other people, low blood pressure means something is wrong. They may not have enough blood flowing to their major organs. In such conditions seek your doctor's advice.

                </p>
               
              <div className=' text-xs text-gray-600 xl:text-sm flex justify-center mb-4'>
                <img
                  data-aos='fade-left'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/bp-range.jpg'
                  alt='Heart'
                  className='w-2/3 object-contain rounded-lg '
                />
              </div>

              <strong>Who is at risk of getting high Blood pressure ?  </strong>
                <p className="mb-4">
                Your risk of high blood pressure is higher if you:

                  <ul className="mt-2 ml-10"> 
                    <li>1. Have a family history of high blood pressure, cardiovascular disease or diabetes</li>
                    <li>2. Age 60 years or older </li>
                    <li>3. High blood cholesterol  </li>
                    <li>4. Use oral contraceptives </li>
                    <li>5. Have obesity  </li>
                    <li>6. Use tobacco products  </li>
                    <li>7. Do not exercise   </li>
                    <li>8. Eat foods that are high in salt.  </li> 
                  </ul>
                </p>

            </section>

            <section
              className='mt-5 flex flex-col lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div
                className=' w-full md:w-full'
                data-aos='fade-left'
                data-aos-easing='linear'
                data-aos-duration='300'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center'>
                  Symptoms of Hypertension
                </h3>
                <p className=' text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                Hypertension has few visible symptoms. However, a hypertensive crisis can cause severe headaches, chest pain, dizziness, difficulty in breathing, nausea, vomiting, blurred vision or other vision changes, anxiety, confusion, buzzing in the ears, nosebleeds, abnormal heart rhythm.

                </p>
              </div>

              <div className='w-full flex md:w-full overflow-hidden justify-center'>
                <img
                  data-aos='fade-left'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-2.jpg'
                  alt='Heart'
                  className='w-full lg:w-3/4 h-full object-contain'
                />
              </div>
            </section>

            <section
              className='mt-5 flex flex-col lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20 '
              style={{ marginTop: 40 }}
            >
              <div
                className=' w-full md:w-full'
                data-aos='fade-left'
                data-aos-easing='linear'
                data-aos-duration='300'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center'>
                What are the reasons for Hypertension ?
                </h3>

                <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                Hypertension typically develops over time and can be attributed to unhealthy lifestyle choices. The causes can be either modifiable risk factors (one can take measures to change them) or non-modifiable risk factors (they cannot be changed).
                <br />
                <strong>Modifiable risk factors</strong> include poor diets, excessive salt consumption, diet heavy in saturated fat and trans fats, low intake of fruits and vegetables, physical inactivity, cigarette and alcohol usage, and being overweight or obese.
                <br />
                <strong>Non-modifiable risk factors</strong> include a family history of hypertension, age {">"}65, and co-existing disorders such as diabetes or renal disease.


                  {/* <p>
                    <h2>Non-modifiable risk factors</h2>
                    1. Family history of Hypertension
                    2. Age is greater than 65
                    3. Co-existing disorders such as diabetes or renal disease
                  </p> */}
                  

                  <div className='w-full flex md:w-full overflow-hidden justify-center'>
                <img
                  data-aos='fade-left'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-3.jpg'
                  alt='Heart'
                  className='w-full lg:w-2/3 h-full object-contain'
                />
              </div>

                  {/* <div class='max-w-md mx-auto bg-white rounded mt-10 text-left'>
                    <h2 class='text-xl font-bold mb-4 '>
                      Non-modifiable risk factors
                    </h2>
                    <p>
                    include a family history of hypertension, age {">"} 65, and co-existing disorders such as diabetes or renal disease. 
                    </p>
                  </div> */}
                </p>
              </div>

              <div className='w-full flex md:w-full overflow-hidden justify-center'>
                <img
                  // data-aos='fade-left'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/Non-modifiable Risk Factors.png'
                  alt='Heart'
                  className='w-full lg:w-2/3 h-full object-contain'
                />
              </div>
            </section>

            <section
              className='mt-5 flex flex-col md:flex-row lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div
                className=' bg-white w-full md:w-full'
                data-aos='fade-down'
                data-aos-easing='linear'
                data-aos-duration='300'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4'>
                  The link between Hypertension and cardiovascular (heart and blood vessels) diseases
                </h3>
                <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                Hypertension, if left untreated, can severely damage the heart. High blood pressure can cause the arteries to harden, which reduces blood and oxygen flow to the heart. 
This can lead to chest discomfort, known as angina, or even a heart attack.  
A heart attack happens when the supply of blood to the heart is cut off, causing heart muscle cells to die due to a lack of oxygen.
The longer the blood flow is restricted, the more damage the heart will suffer. 
This damage can result in heart failure, where the heart cannot pump blood and oxygen efficiently to vital organs, and an irregular heartbeat, which can cause sudden death. Hypertension can also cause a stroke by rupturing or blocking arteries that supply blood and oxygen to the brain.
 However, effective treatment of hypertension significantly reduces the risk of heart-related diseases.
                </p>
              </div>

              <div className='w-full flex md:w-full overflow-hidden'>
                <img
                  // data-aos='fade-left'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/uh-4.jpg'
                  alt='Heart'
                  className='w-full h-full object-contain ml-5 mt-10'
                />
              </div>
            </section>

            <section
              className='mt-5 flex flex-col-reverse lg:p-20 md:p-10 gap-4 p-8 mb-4  bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div className='w-full flex md:w-full overflow-hidden justify-center'>
                <img
                  data-aos='fade-left'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  src='../assets/Lifestyle Modifications.png'
                  alt='Heart'
                  className='w-full lg:w-3/4 h-full object-contain'
                />
              </div>
              <div
                data-aos='fade-down'
                data-aos-easing='linear'
                data-aos-duration='300'
                className='bg-white w-full md:w-full'
              >
                <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center'>
                  How to manage Hypertension and Cardiovascular disease risks?
                </h3>
                <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
                
                With proper care and lifestyle changes, we can manage our hypertension and reduce the risk of cardiovascular risk. Some of the important  steps one can follow are to reduce the risk are

                </p>
              </div>
            </section>

            <section
              data-aos='fade-down'
              data-aos-easing='linear'
              data-aos-duration='300'
              className='flex flex-col md:flex-row gap-0 mb-4 lg:p-20 md:p-10 p-8 bg-white lg:-ml-20 lg:-mr-20'
              style={{ marginTop: 40 }}
            >
              <div className=''>
                <p className=' text-xs text-gray-600 xl:text-sm'>
                  <ul>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        1. Healthy eating:
                      </strong>
                     Adopt a balanced diet rich in fruits, vegetables, whole grains, lean proteins, and low-fat dairy products. Limit the consumption of processed foods, sodium (salt), saturated and trans fats, and sugary beverages.
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        2. Regular physical activity:
                      </strong>
                      Engage in at least 150 minutes of moderate-intensity aerobic exercise or 75 minutes of vigorous exercise per week. 
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        3. Weight management:
                      </strong>{" "}
                      Maintain a healthy weight by balancing calorie intake and physical activity. Reducing even 5-10% of the body weight can significantly lower blood pressure.
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        4. Salt reduction:
                      </strong>{" "}
                      Limit sodium intake to  {"<"} 2,300 mg per day.  
                    </li>
                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        {" "}
                        5. Moderating alcohol consumption:{" "}
                      </strong>{" "}
                      Moderate alcohol consumption by limiting one drink per day for women and up to two drinks per day for men.
                    </li>

                    <li>
                      <strong className='text-base text-[#2d95a5]'>
                        {" "}
                        6. Smoking cessation:{" "}
                      </strong>{" "}
                     
                      Smoking damages blood vessels and increases the risk of heart disease and stroke. Hence, it is recommended to quit smoking and avoid secondhand smoke.

                    </li>

                  </ul>
                   
                  <p className='mt-5'>
                    <strong className='text-lg text-[#2d95a5]'>
                      {" "}
                      Medication management
                    </strong>{" "}
                    <br />
                    {/* 1. Discuss with the clinician to determine the optimal
                    medications and understand the potential side effects or
                    interactions. <br /> */}
                    1. Adhere to the prescribed medication: Establish a routine for taking medications by setting reminders. Consult the clinician before stopping or changing the prescribed medication.
                    <br />
                    2. Conduct regular check-ups to monitor the blood pressure and adjust medications if necessary.
                    <br />
                  </p>
                  <p className='mt-5'>
                    <strong className='text-lg text-[#2d95a5]'>
                      {" "}
                      Monitoring blood pressure
                    </strong>{" "}
                    <br />
                    1. Learn how to use a home blood pressure monitor correctly. Keep a record of blood pressure regularly and seek medical attention if there is any fluctuation. <br />
                    2. Understand the target blood pressure range and the significance of reaching and maintaining it. 
                  </p>

                  <p className='mt-5 '>
                    <strong className='text-lg text-[#2d95a5]'>
                      Recognizing warning signs
                    </strong>{" "}

                    <div className="w-full flex justify-center md:w-full overflow-hidden mt-10 mb-10">
                      <img
                        src="../assets/Recognizing warning signs.png"
                        alt="Heart"
                        className="w-full lg:w-2/3 h-full object-contain mb-4"
                      />
                    </div>


                    <br />
                    1. Learn to recognize symptoms that may indicate a complication of hypertension or CVD such as chest pain, shortness of breath, severe headaches, dizziness, or sudden weakness. <br />
                    2. Seek immediate medical attention or call emergency services if there are any concerning symptoms.
                  </p>

                  <p className='mt-5'>
                    <h2 className='fon-xl'>
                      {" "}
                      <strong className='text-lg text-[#2d95a5]'>
                        References
                      </strong>
                    </h2>
                    <br />
                    
                   
                   <div className="   items-center flex-wrap inline-block ">
                   
                   1. Research C for DE and. High Blood Pressure–Understanding
                     the Silent Killer. FDA [Internet]. 2021 Jan 21 [cited 2023
                     Jun 7]; Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.fda.gov/drugs/special-features/high-blood-pressure-understanding-silent-killer  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    2. Hypertension [Internet]. [cited 2023 Jun 7]. Available
                     from:{" "}
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.who.int/health-topics/hypertension '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                   3. What is blood pressure and how is it measured? In:
                    InformedHealth.org [Internet] [Internet]. Institute for
                    Quality and Efficiency in Health Care (IQWiG); 2019 [cited
                    2023 Jun 7]. Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.ncbi.nlm.nih.gov/books/NBK279251/  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    4. Desai AN. High Blood Pressure. JAMA. 2020 Sep
                                         22;324(12):1254–5.
                   
                    {/* <div className='inline-block border my-3'>
                    <a
                      href='https://www.ncbi.nlm.nih.gov/books/NBK279251/  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div> */}
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    5. Understanding Blood Pressure Readings [Internet].
                                         www.heart.org. [cited 2023 Jun 7]. Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    6. Hypertension [Internet]. [cited 2023 Jun 7]. Available
                                         from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.who.int/news-room/fact-sheets/detail/hypertension  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    7. Cardiovascular diseases (CVDs) [Internet]. [cited 2023
                                          Jun 7]. Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href=' https://www.who.int/news-room/fact-sheets/detail/cardiovascular-diseases-(cvds)  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    8. Wu CY, Hu HY, Chou YJ, Huang N, Chou YC, Li CP. High
                     Blood Pressure and All-Cause and Cardiovascular Disease
                     Mortalities in Community-Dwelling Older Adults. Medicine
                     (Baltimore). 2015 Oct 30;94(47):e2160.
                   
                    {/* <div className='inline-block border my-3'>
                    <a
                      href='https://www.ncbi.nlm.nih.gov/books/NBK279251/  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div> */}
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    9. Hypertension [Internet]. World Heart Federation. [cited
                                           2023 Jun 7]. Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://world-heart-federation.org/what-we-do/hypertension/ '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    10. Fuchs FD, Whelton PK. High Blood Pressure and
                    Cardiovascular Disease. Hypertension. 2020 Feb;75(2):285–92.
                   
                    {/* <div className='inline-block border my-3'>
                    <a
                      href='https://www.ncbi.nlm.nih.gov/books/NBK279251/  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div> */}
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    11. Heart Disease and Stroke | CDC [Internet]. 2022 [cited
                                           2023 Jun 7]. Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.cdc.gov/chronicdisease/resources/publications/factsheets/heart-disease-stroke.htm  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    12. Cubrilo-Turek M. Hypertension and Coronary Heart
                                         Disease. EJIFCC. 2003 Jul 3;14(2):67–73.
                   
                    {/* <div className='inline-block border my-3'>
                    <a
                      href='https://www.ncbi.nlm.nih.gov/books/NBK279251/  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div> */}
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    13. Screening, Diagnosis, Assessment, and Management of
                     Primary Hypertension in Adults in India, Ministry of Health
                     & Family Welfare Government of India]. Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://nhm.gov.in/images/pdf/guidelines/nrhm-guidelines/stg/Hypertension_full.pdf  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    14. CDC High Blood Pressure Home [Internet]. Centers for
                     Disease Control and Prevention. 2023 [cited 2023 Jun 7].
                     Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.cdc.gov/bloodpressure/index.htm '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    <br />
                    <br />
                    <div className="   items-center flex-wrap inline-block ">
                   
                    15. Manage High Blood Pressure | cdc.gov [Internet]. Centers
                     for Disease Control and Prevention. 2023 [cited 2023 Jun 7].
                     Available from:
                   
                    <div className='inline-block  my-3'>
                    <a
                      href='https://www.cdc.gov/bloodpressure/manage.htm  '
                      className='bg-[#2d95a5] p-1 rounded-sm ml-3 text-white hover:bg-slate-400'
                    >
                      Click
                    </a>
                    </div>
                   </div>
                   
                    {/* <br />
                    <br /> */}

                  </p>


                </p>
              </div>
            </section>

            <Ads />
            {/* <Subscribe /> */}
          </div>
          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default UnderstandingHypertension;







// import NewNavbar from "../Components/Common/NewNavbar";
// import NewFooter from "../Components/Common/NewFooter";
// import Copyright from "../Components/FirstComponents/Copyright";
// import Ads from "../Components/FirstComponents/Ads";
// import Subscribe from "../Components/FirstComponents/Subscribe";
// import Banner from "../Utils/Banner";

// function UnderstandingHypertension() {
//   return (
//     <>
//       <div
//         className='bg-contain bg-no-repeat bg-gray-100 '
//         style={{
//           backgroundImage: "url('/assets/background.png')",
//           backgroundPosition: "right top",
//         }}
//       >
//         <NewNavbar />
//         <div className='mx-auto '>
//           {/* <Hero /> */}
//           {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10">
//             UNDERSTANDING ON HYPERTENSION & CVD
//           </h1> */}

//           <Banner
//             title='UNDERSTANDING ON HYPERTENSION & CVD'
//             imageUrl='/assets/Second_Banner.png'
//           />

//           <div className='mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14  xl:ml-32 xl:mr-32'>
//             {/* <MythsFacts /> */}
//             {/* <HeartDisease /> */}

//             <section
//               className='bg-white rounded-lg lg:p-20 md:p-10 gap-4 p-5 lg:-ml-20 lg:-mr-20'
//               style={{ marginTop: 0 }}
//             >
//               <div className=' text-xs text-gray-600 xl:text-sm flex justify-center'>
//                 <img
//                   data-aos='fade-left'
//                   data-aos-easing='linear'
//                   data-aos-duration='300'
//                   src='../assets/uh-1.png'
//                   alt='Heart'
//                   className='w-2/3 object-contain rounded-lg '
//                 />
//               </div>
//             </section>

//             <section
//               className='mt-5 flex flex-col lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20'
//               style={{ marginTop: 40 }}
//             >
//               <div
//                 className=' w-full md:w-full'
//                 data-aos='fade-left'
//                 data-aos-easing='linear'
//                 data-aos-duration='300'
//               >
//                 <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4  flex justify-center'>
//                   Symptoms of Hypertension
//                 </h3>
//                 <p className=' text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
//                 Hypertension has few visible symptoms. However, a hypertensive crisis can cause severe headaches, chest pain, dizziness, difficulty in breathing, nausea, vomiting, blurred vision or other vision changes, anxiety, confusion, buzzing in the ears, nosebleeds, abnormal heart rhythm.

//                 </p>
//               </div>

//               <div className='w-full flex md:w-full overflow-hidden justify-center'>
//                 <img
//                   data-aos='fade-left'
//                   data-aos-easing='linear'
//                   data-aos-duration='300'
//                   src='../assets/uh-2.jpg'
//                   alt='Heart'
//                   className='w-full lg:w-3/4 h-full object-contain'
//                 />
//               </div>
//             </section>

//             <section
//               className='mt-5 flex flex-col lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20 '
//               style={{ marginTop: 40 }}
//             >
//               <div
//                 className=' w-full md:w-full'
//                 data-aos='fade-left'
//                 data-aos-easing='linear'
//                 data-aos-duration='300'
//               >
//                 <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center'>
//                 What are the reasons for Hypertension ?
//                 </h3>

//                 <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
//                 Hypertension typically develops over time and can be attributed to unhealthy lifestyle choices. The causes can be either modifiable risk factors (one can take measures to change them) or non-modifiable risk factors (they cannot be changed).
//                   {/* <p>
//                     <h2>Non-modifiable risk factors</h2>
//                     1. Family history of Hypertension
//                     2. Age is greater than 65
//                     3. Co-existing disorders such as diabetes or renal disease
//                   </p> */}
//                   <div class='max-w-md mx-auto bg-white rounded mt-10 text-left'>
//                     <h2 class='text-xl font-bold mb-4 '>
//                     Modifiable risk factors
//                     </h2>
                    
//                     <p>
//                     include poor diets, excessive salt consumption, diet heavy in saturated fat and trans fats, low intake of fruits and vegetables, physical inactivity, cigarette and alcohol usage, and being overweight or obese. 
//                     </p>
//                   </div>

//                   <div className='w-full flex md:w-full overflow-hidden justify-center'>
//                 <img
//                   data-aos='fade-left'
//                   data-aos-easing='linear'
//                   data-aos-duration='300'
//                   src='../assets/uh-3.png'
//                   alt='Heart'
//                   className='w-full lg:w-2/3 h-full object-contain'
//                 />
//               </div>

//                   <div class='max-w-md mx-auto bg-white rounded mt-10 text-left'>
//                     <h2 class='text-xl font-bold mb-4 '>
//                       Non-modifiable risk factors
//                     </h2>
//                     <p>
//                     include a family history of hypertension, age > 65, and co-existing disorders such as diabetes or renal disease. 
//                     </p>
//                   </div>
//                 </p>
//               </div>

//               <div className='w-full flex md:w-full overflow-hidden justify-center'>
//                 <img
//                   data-aos='fade-left'
//                   data-aos-easing='linear'
//                   data-aos-duration='300'
//                   src='../assets/uh-4.png'
//                   alt='Heart'
//                   className='w-full lg:w-2/3 h-full object-contain'
//                 />
//               </div>
//             </section>

//             <section
//               className='mt-5 flex flex-col md:flex-row lg:p-20 md:p-10 gap-4 p-8 mb-4 bg-white lg:-ml-20 lg:-mr-20'
//               style={{ marginTop: 40 }}
//             >
//               <div
//                 className=' bg-white w-full md:w-full'
//                 data-aos='fade-down'
//                 data-aos-easing='linear'
//                 data-aos-duration='300'
//               >
//                 <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4'>
//                   The link between Hypertension and cardiovascular (heart and
//                   blood vessels) diseases
//                 </h3>
//                 <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
//                   Hypertension, if left untreated, can severely damage the
//                   heart. High blood pressure can cause the arteries to harden,
//                   which reduces blood and oxygen flow to the heart. This can
//                   lead to chest discomfort, known as angina, or even a heart
//                   attack. A heart attack happens when the supply of blood to the
//                   heart is cut off, causing heart muscle cells to die due to a
//                   lack of oxygen. The longer the blood flow is restricted, the
//                   more damage the heart will suffer. This damage can result in
//                   heart failure, where the heart cannot pump blood and oxygen
//                   efficiently to vital organs, and an irregular heartbeat, which
//                   can cause sudden death. Hypertension can also cause a stroke
//                   by rupturing or blocking arteries that supply blood and oxygen
//                   to the brain. However, effective treatment of hypertension
//                   significantly reduces the risk of heart-related diseases.
//                 </p>
//               </div>

//               <div className='w-full flex md:w-full overflow-hidden'>
//                 <img
//                   // data-aos='fade-left'
//                   data-aos-easing='linear'
//                   data-aos-duration='300'
//                   src='../assets/uh-4.jpg'
//                   alt='Heart'
//                   className='w-full h-full object-contain ml-5 mt-10'
//                 />
//               </div>
//             </section>

//             <section
//               className='mt-5 flex flex-col-reverse lg:p-20 md:p-10 gap-4 p-8 mb-4  bg-white lg:-ml-20 lg:-mr-20'
//               style={{ marginTop: 40 }}
//             >
//               <div className='w-full flex md:w-full overflow-hidden justify-center'>
//                 <img
//                   data-aos='fade-left'
//                   data-aos-easing='linear'
//                   data-aos-duration='300'
//                   src='../assets/uh-5.jpg'
//                   alt='Heart'
//                   className='w-full lg:w-3/4 h-full object-contain'
//                 />
//               </div>
//               <div
//                 data-aos='fade-down'
//                 data-aos-easing='linear'
//                 data-aos-duration='300'
//                 className='bg-white w-full md:w-full'
//               >
//                 <h3 className='text-[#2d95a5] font-semibold text-xl lg:text-3xl mb-4 flex justify-center'>
//                   How to manage Hypertension and Cardiovascular disease risks?
//                 </h3>
//                 <p className='text-gray-600 xl:text-md text-justify lg:text-lg md:text-md sm:p-4 lg:-ml-10 lg:-mr-10'>
//                 With proper care and lifestyle changes, we can manage our hypertension and reduce the risk of cardiovascular risk. Some of the important steps one can follow are to reduce the risk are
//                 </p>
//               </div>
//             </section>

//             <section
//               data-aos='fade-down'
//               data-aos-easing='linear'
//               data-aos-duration='300'
//               className='flex flex-col md:flex-row gap-0 mb-4 lg:p-20 md:p-10 p-8 bg-white lg:-ml-20 lg:-mr-20'
//               style={{ marginTop: 40 }}
//             >
//               <div className=''>
//                 <p className=' text-xs text-gray-600 xl:text-sm'>
//                   <ul>
//                     <li>
//                       <strong className='text-base text-[#2d95a5]'>
//                         1. Healthy eating:
//                       </strong>
//                       Adopt a balanced diet rich in fruits, vegetables, whole grains, lean proteins, and low-fat dairy products. Limit the consumption of processed foods, sodium (salt), saturated and trans fats, and sugary beverages.
//                     </li>
//                     <li>
//                       <strong className='text-base text-[#2d95a5]'>
//                         2. Regular physical activity:
//                       </strong>
//                       Engage in at least 150 minutes of moderate-intensity aerobic exercise or 75 minutes of vigorous exercise per week. 
//                     </li>
//                     <li>
//                       <strong className='text-base text-[#2d95a5]'>
//                         3. Weight management:
//                       </strong>{" "}
//                       Maintain a healthy weight by balancing calorie intake and physical activity. Reducing even 5-10% of the body weight can significantly lower blood pressure.
//                     </li>
//                     <li>
//                       <strong className='text-base text-[#2d95a5]'>
//                         4. Salt reduction:
//                       </strong>{" "}
//                       Limit sodium intake to  {"<2"}, 300 mg per day. 
//                     </li>
//                     <li>
//                       <strong className='text-base text-[#2d95a5]'>
//                         {" "}
//                         5. Moderating alcohol consumption:{" "}
//                       </strong>{" "}
//                       Moderate alcohol consumption by limiting one drink per day for women and up to two drinks per day for men.
//                     </li>
//                   </ul>
//                   <p className='mt-3 bg-slate-100 rounded-sm p-1'>
//                     <strong>Smoking cessation:</strong> Smoking damages blood vessels and increases the risk of heart disease and stroke. Hence, it is recommended to quit smoking and avoid second hand smoke.

//                   </p>
//                   <p className='mt-5'>
//                     <strong className='text-lg text-[#2d95a5]'>
//                       {" "}
//                       Medication management
//                     </strong>{" "}
//                     <br />
//                     {/* 1. Discuss with the clinician to determine the optimal
//                     medications and understand the potential side effects or
//                     interactions. <br /> */}
//                     1. Establish a routine for taking medications by setting reminders. Consult the clinician before stopping or changing the prescribed medication.
//                     <br />
//                     2.Conduct regular check-ups to monitor the blood pressure and adjust medications if necessary. 
//                     <br />
//                   </p>
//                   <p className='mt-5'>
//                     <strong className='text-lg text-[#2d95a5]'>
//                       {" "}
//                       Monitoring blood pressure
//                     </strong>{" "}
//                     <br />
//                     1. Learn how to use a home blood pressure monitor correctly. Keep a record of blood pressure regularly and seek medical attention if there is any fluctuation. <br />
//                     2. Understand the target blood pressure range and the significance of reaching and maintaining it. 
//                   </p>

//                   <p className='mt-5'>
//                     <strong className='text-lg text-[#2d95a5]'>
//                       Recognizing warning signs
//                     </strong>{" "}
//                     <br />
//                     1. Learn to recognize symptoms that may indicate a
//                     complication of hypertension or CVD such as chest pain,
//                     shortness of breath, severe headaches, dizziness, or sudden
//                     weakness. <br />
//                     2. Seek immediate medical attention or call emergency
//                     services if there is any concerning symptoms.
//                   </p>

//                   <p className='mt-5'>
//                     <h2 className='fon-xl'>
//                       {" "}
//                       <strong className='text-lg text-[#2d95a5]'>
//                         References
//                       </strong>
//                     </h2>
//                     <br />
//                     1. Research C for DE and. High Blood Pressure–Understanding
//                     the Silent Killer. FDA [Internet]. 2021 Jan 21 [cited 2023
//                     Jun 7]; Available from:
//                     <a
//                       href=' https://www.fda.gov/drugs/special-features/high-blood-pressure-understanding-silent-killer'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     2. Hypertension [Internet]. [cited 2023 Jun 7]. Available
//                     from:{" "}
//                     <a
//                       href='https://www.who.int/health-topics/hypertension '
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     3. What is blood pressure and how is it measured? In:
//                     InformedHealth.org [Internet] [Internet]. Institute for
//                     Quality and Efficiency in Health Care (IQWiG); 2019 [cited
//                     2023 Jun 7]. Available from:
//                     <a
//                       href='https://www.ncbi.nlm.nih.gov/books/NBK279251/  '
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     4. Desai AN. High Blood Pressure. JAMA. 2020 Sep
//                     22;324(12):1254–5.
//                     <br />
//                     <br />
//                     5. Understanding Blood Pressure Readings [Internet].
//                     www.heart.org. [cited 2023 Jun 7]. Available from:
//                     <a
//                       href=' https://www.heart.org/en/health-topics/high-blood-pressure/understanding-blood-pressure-readings'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     6. Hypertension [Internet]. [cited 2023 Jun 7]. Available
//                     from:
//                     <a
//                       href=' https://www.who.int/news-room/fact-sheets/detail/hypertension'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     7. Cardiovascular diseases (CVDs) [Internet]. [cited 2023
//                     Jun 7]. Available from:
//                     <a
//                       href=' https://www.who.int/news-room/fact-sheets/detail/cardiovascular-diseases-(cvds)'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     8. Wu CY, Hu HY, Chou YJ, Huang N, Chou YC, Li CP. High
//                     Blood Pressure and All-Cause and Cardiovascular Disease
//                     Mortalities in Community-Dwelling Older Adults. Medicine
//                     (Baltimore). 2015 Oct 30;94(47):e2160.
//                     <br />
//                     <br />
//                     9. Hypertension [Internet]. World Heart Federation. [cited
//                     2023 Jun 7]. Available from:
//                     <a
//                       href=' https://world-heart-federation.org/what-we-do/hypertension/'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     10. Fuchs FD, Whelton PK. High Blood Pressure and
//                     Cardiovascular Disease. Hypertension. 2020 Feb;75(2):285–92.
//                     <br />
//                     <br />
//                     11. Heart Disease and Stroke | CDC [Internet]. 2022 [cited
//                     2023 Jun 7]. Available from:
//                     <a
//                       href=' https://www.cdc.gov/chronicdisease/resources/publications/factsheets/heart-disease-stroke.htm'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     12. Cubrilo-Turek M. Hypertension and Coronary Heart
//                     Disease. EJIFCC. 2003 Jul 3;14(2):67–73.
//                     <br />
//                     <br />
//                     13. Screening, Diagnosis, Assessment, and Management of
//                     Primary Hypertension in Adults in India, Ministry of Health
//                     & Family Welfare Government of India]. Available from:
//                     <a
//                       href=' https://nhm.gov.in/images/pdf/guidelines/nrhm-guidelines/stg/Hypertension_full.pdf'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     14. CDC High Blood Pressure Home [Internet]. Centers for
//                     Disease Control and Prevention. 2023 [cited 2023 Jun 7].
//                     Available from:
//                     <a
//                       href=' https://www.cdc.gov/bloodpressure/index.htm'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                     <br />
//                     <br />
//                     15. Manage High Blood Pressure | cdc.gov [Internet]. Centers
//                     for Disease Control and Prevention. 2023 [cited 2023 Jun 7].
//                     Available from:
//                     <a
//                       href='https://www.cdc.gov/bloodpressure/manage.htm'
//                       className='bg-[#2d95a5] p-2 rounded-sm ml-3 text-white hover:bg-slate-400'
//                     >
//                       Click
//                     </a>
//                   </p>
//                 </p>
//               </div>
//             </section>

//             <Ads />
//             {/* <Subscribe /> */}
//           </div>
//           <NewFooter />
//           <Copyright />
//         </div>
//       </div>
//     </>
//   );
// }

// export default UnderstandingHypertension;


