import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";

const Videos = ({ contents, subCategory, language, buttonText }) => {
  return (
    <>
      <section className='font-ubuntu mb-5'>
        <div className='flex justify-between pt-4 mb-2 '>
          <h1 className='text-[#2d95a5] font-bold text-2xl md:text-3xl'>
            {/* Yoga Videos */}
          </h1>
          {/* <button className='bg-[#2d95a5] hover:bg-[#91c8d0] text-white font-bold py-2 px-4 rounded-full text-xs lg:text-md md:mt-0'>
            View all
          </button> */}
        </div>

        <div className='flex flex-col justify-center'>
          <div className='grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3'>
            {contents
              ?.filter((content) => {
                const languageMatch =
                  language.id === 0 || content.language_id === language.id;
                const subCategoryMatch =
                  subCategory.id === 0 ||
                  content.content_subcategory_id === subCategory.id;
                return languageMatch && subCategoryMatch;
              })
              ?.map((content, index) => (
                <div
                  data-aos='fade-left'
                  data-aos-easing='ease-in-sine'
                  data-aos-duration='300'
                  crossOrigin='anonymous'
                  key={index}
                  className='bg-white border rounded-xl '
                >
                  {/* <div className="border border-[#2d95a5] m-3 rounded-lg overflow-hidden">
                  <img
                    crossOrigin="anonymous"
                    className="object-contain w-full h-48"
                    src={`${process.env.REACT_APP_BASE_FILE_URL}/${content.thumbnail}`}
                    alt={`Card ${index + 1}`}
                  />
                </div> */}

                  <div className='m-3 overflow-hidden rounded-sm'>
                    <img
                      crossOrigin='anonymous'
                      className='object-contain w-full'
                      src={content.thumbnail}
                      alt={`Card ${index + 1}`}
                    />
                  </div>
                  <div className='px-2 text-[#2d95a5]'>
                    <div className='text-base font-bold'>
                      <Link to={`/details/${content.id}`}>
                        {" "}
                        {content.title}
                      </Link>
                    </div>
                  </div>

                  <div className='flex items-center justify-between px-2 py-4'>
                    <div className='text-xs font-normal'>
                      {moment(content.createdAt).format("LL")}
                    </div>

                    <div className=''>
                      <Link
                        to={`/details/${content.id}`}
                        className='border-2 border-[#2d95a5] hover:bg-[#2d95a5] hover:text-white font-bold py-2 px-4 text-sm rounded-full'
                      >
                        {buttonText}
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Videos;
