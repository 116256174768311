import NewFooter from "../Components/Common/NewFooter";
import Copyright from "../Components/FirstComponents/Copyright";
import BlogAndArticle from "../Components/FirstComponents/BlogAndArticle";
import HeartDisease from "../Components/SecondComponents/HeartDisease";
import NewNavbar from "../Components/Common/NewNavbar"; 
import Banner from "../Utils/Banner"; 
import Subscribe from "../Components/FirstComponents/Subscribe";
 

function About() {
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // }, []);

  return (
    <>
      <div
        className='overflow-hidden bg-contain bg-no-repeat bg-gray-00 '
        style={{
          // backgroundImage: "url('/assets/background.png')",
          backgroundPosition: "right top",
        }}
      >
        <NewNavbar />
        <div className='mx-auto '>
          {/* <Hero /> */}
          {/* <h1 className="text-[#2d95a5] bg-white  bg-opacity-80 font-bold text-2xl md:text-3xl px-auto pl-4 pr-4 md:pl-6 md:pr-6 lg:pr-14 lg:pl-14  xl:pl-32 xl:pr-32 py-10 uppercase">
            About Us
          </h1> */}
          <Banner title='About' imageUrl='/assets/Second_Banner.png' />

          {/* About Section */}
          <section
            className='relative top-0 bg-no-repeat bg-left-top  z-10 flex text-justify mt-20 mb-24'
            style={{
              backgroundImage: "url(/assets/New-Hero/Background-1.png)",
            }}
          >
            <div className='grid grid-cols-1 lg:grid-cols-2 items-center justify-center lg:gap-2 xl:gap-0 p-6 xl:p-0'>
              <div className='col-span-1 rounded-3xl flex items-center justify-center'>
                <img
                  data-aos='fade-left'
                  data-aos-easing='linear'
                  data-aos-duration='300'
                  crossOrigin='anonymous'
                  src='/assets/New-Hero/about-mission-organ-protection.png'
                  alt='background'
                  className='w-[550px] rounded-lg'
                />
              </div>

              <div
                className='col-span-1 xl:w-[650px] mt-5 lg:mt-0 flex flex-col items-center lg:items-start justify-center '
                // data-aos='fade-left'
                data-aos-easing='linear'
                data-aos-duration='300'
                crossOrigin='anonymous'
              >
                {/* <span className="text-gray uppercase text-sm font-medium tracking-wider ">
                INTRODUCTION TO MISSION ORGAN PROTECTION
                </span> */}
                <h1 className='text-primary font-inter text-3xl lg:text-4xl font-semibold '>
                  Mission organ protection
                </h1>
                <p className='text-gray-600 text-md font-inter font-normal mt-5 text-justify md:p-0 mr-10'>
                India is often referred to as the ‘diabetes capital of the world’ due to its significant burden on both individuals and the healthcare system.
                 The prevalence has reached alarming levels with estimates indicating that one in six people in urban areas 
                 and one in ten people in rural areas are living with diabetes. The disease affects people across all
                  age groups, including children and young adults. <br /> 

                  Furthermore, diabetes-related complications, such as heart and kidney diseases, eye problems, 
                  and nerve damage, contribute to a significant healthcare burden. The increase in diabetes 
                  prevalence in the country can be attributed to several factors. It is projected that by 2025,
                   India will have approximately 69 million cases of diabetes, a significant majority of them 
                   are remaining undiagnosed. Effective diabetes care requires coordination across all levels 
                   of the healthcare system, with a particular emphasis on patients' understanding, attitudes,
                    and perceptions regarding awareness, treatment, and adherence to recommendations. <br />
                    Mission Organ Protection is an initiative to educate people on the need of organ 
                    protection and encourage them to adopt proactive health measures and to promote healthy 
                    lifestyle choices to prevent organ damage especially if they have chronic diseases like 
                    diabetes & Hypertension. <br />
                    Also, collaborate with healthcare providers, Health associates and government bodies to   create awareness among masses. 




                </p>
              </div>
            </div>
          </section>

          {/* About Section */}

          <div className='mx-auto ml-4 mr-4 md:ml-6 md:mr-6 lg:mr-14 lg:ml-14 mb-10 xl:ml-32 xl:mr-32 -mt-10 '>
            {/* <MythsFacts /> */}
            <HeartDisease data-aos='fade-left' />
            <div
              data-aos='fade-left'
              className='flex flex-col md:flex-row justify-center bg-white py-10 px-4 md:px-10 gap-6 rounded-lg mb-10'
            ></div>

            {/* <BlogAndArticle /> */}
            {/* <Ads /> */}
            <Subscribe />
          </div>

          <NewFooter />
          <Copyright />
        </div>
      </div>
    </>
  );
}

export default About;
