// import React from "react";
// import { FaArrowRightLong } from "react-icons/fa6";
// import backgroundImg from './assets/New-Hero/Background-1.png';

// const AboutUs = () => {
//   return (
//     <section
//       className='border border-red-500 overflow-hidden  relative top-0 bg-no-repeat bg-left-top lg:h-screen z-10 flex justify-center '
//       style={{
//          backgroundImage: "url(/assets/New-Hero/Background-1.png)",

//         border: "2px solid blue",
        
//       }}
//     >
//       <div className='grid grid-cols-1 lg:grid-cols-2 items-center justify-center lg:gap-2 xl:gap-0 p-6 xl:p-0'>
//         <div className='col-span-1 border-2 rounded-3xl border-white flex items-center justify-center'>
//           <img
//             data-aos='fade-left'
//             data-aos-easing='linear'
//             data-aos-duration='300'
//             crossOrigin='anonymous'
//             src='/assets/New-Hero/mission-organ.png'
//             alt='background'
//             className='w-[550px] rounded-xl'
//           />
//         </div>

//         <div
//           className='col-span-1 xl:w-[680px] mt-5 lg:mt-0 flex flex-col items-center lg:items-start justify-center'
//           data-aos='fade-left'
//           data-aos-easing='linear'
//           data-aos-duration='300'
//           crossOrigin='anonymous'
//         >
//           <span className='text-secondary uppercase text-xl font-medium tracking-wider '>
//             About
//           </span>
//           <h1 className='text-textColor font-inter text-3xl lg:text-5xl font-semibold mt-2'>
//             Mission organ protection
//           </h1>
//           <p className='text-gray-600 font-inter text-lg mt-5 md:p-0  text-justify'>
//             India is often referred to as the ‘diabetes capital of the world’
//             due to its significant burden on both individuals and the healthcare
//             system. The prevalence has reached alarming levels with estimates
//             indicating that one in six people in urban areas and one in ten
//             people in rural areas are living with diabetes. <br /> <br />
//             The diabetes affects people across all age groups, including children
//             and young adults. Furthermore, diabetes-related complications, such
//             as heart and kidney diseases, eye problems, and nerve damage,
//             contribute to a significant healthcare burden...
//           </p>

//           <button className='bg-primary flex items-center gap-2 border-primary uppercase text-sm text-white font-inter font-semibold lg:text-xl mt-5 lg:mt-10 p-3 lg:px-8 py-4 px-4  rounded-full hover:bg-transparent hover:text-primary border-2 hover:border-primary'>
//             <a href='/introduction'>Read More</a>
//             <FaArrowRightLong />
//           </button>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AboutUs;


import React, { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";

const AboutUs = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth < 768);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

   // Update isMobile when screen is resized
   useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <section
      className='z-0 overflow-hidden  relative top-0 bg-no-repeat bg-left-top lg:h-screen  flex justify-center'
      style={{
        backgroundImage: isMobile ? "none" : "url(/assets/New-Hero/Background-1.png)",
      }}
    >
      <div className='grid grid-cols-1 lg:grid-cols-2 items-center justify-center lg:gap-2 xl:gap-0 p-6 xl:p-0'>
        <div className='col-span-1 border-2 rounded-3xl border-white flex items-center justify-center'>
          <img
            data-aos='fade-left'
            data-aos-easing='linear'
            data-aos-duration='300'
            crossOrigin='anonymous'
            src='/assets/New-Hero/mission-organ.png'
            alt='background'
            className='w-[550px] rounded-xl'
          />
        </div>

        <div
          className=' col-span-1 xl:w-[600px] mt-5 lg:mt-0 flex flex-col items-center lg:items-start justify-center lg:pl-6 '
          data-aos='fade-left'
          data-aos-easing='linear'
          data-aos-duration='300'
          crossOrigin='anonymous'
        >
          <span className='text-secondary uppercase text-xl font-medium tracking-wider '>
            About
          </span>
          <h1 className='text-textColor font-inter text-3xl lg:text-5xl font-semibold mt-2'>
            Mission organ protection
          </h1>
          <p className=' text-gray-600 font-inter text-lg w-[95%] mt-5 md:p-0  text-justify '>
            India is often referred to as the ‘diabetes capital of the world’
            due to its significant burden on both individuals and the healthcare
            system. The prevalence has reached alarming levels with estimates
            indicating that one in six people in urban areas and one in ten
            people in rural areas are living with diabetes. <br /> <br />
            The diabetes affects people across all age groups, including children
            and young adults. Furthermore, diabetes-related complications, such
            as heart and kidney diseases, eye problems, and nerve damage,
            contribute to a significant healthcare burden...
          </p>

          <button className='bg-primary flex items-center gap-2 border-primary uppercase text-sm text-white font-inter font-semibold lg:text-xl mt-5 lg:mt-10 p-3 lg:px-8 py-4 px-4  rounded-full hover:bg-transparent hover:text-primary border-2 hover:border-primary'>
            <a href='/introduction'>Read More</a>
            <FaArrowRightLong />
          </button>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
