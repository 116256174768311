import React, { useState } from "react";

const ChatRegForm = ({ setLogin, setUser, user }) => {
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    return alert("Chatbot is Under activation process");
    setErrors(handleError(user));
    if (
      user.name !== "" ||
      user.email !== "" ||
      user.phone !== "" ||
      user.state !== ""
    ) {
      setLogin(true);
      console.log(user);
    }
  };

  const handleError = (value) => {
    const error = {};
    if (value.name === "") error.Name = "Enter the name.";
    if (value.email === "") error.Email = "Enter the email.";
    if (value.phone === "") error.Phone = "Enter the phone number.";
    if (value.state === "") error.State = "Select a state.";

    return error;
  };

  return (
    <div className='form-container'>
      <form onSubmit={handleSubmit}>
        <label htmlFor='name'>
          <h4 className='form-label'>Name</h4>
          <input
            id='name'
            name='name'
            type='text'
            placeholder='Name'
            className='form-input-container'
            onChange={handleChange}
            value={user.name}
          />
          <p className='error'>{errors.Name}</p>
        </label>
        <label htmlFor='email'>
          <h4 className='form-label'>Email</h4>
          <input
            id='email'
            name='email'
            type='email'
            placeholder='Email'
            className='form-input-container'
            onChange={handleChange}
            value={user.email}
          />
          <p className='error'>{errors.Email}</p>
        </label>
        <label htmlFor='state'>
          <h4 className='form-label'>State</h4>
          <input
            id='state'
            name='state'
            type='text'
            placeholder='State'
            className='form-input-container'
            onChange={handleChange}
          />
          <p className='error'>{errors.State}</p>
        </label>
        <label htmlFor='phone_no'>
          <h4 className='form-label'>Phone-No</h4>
          <input
            id='phone_no'
            name='phone'
            type='tel'
            placeholder='+91'
            maxLength='10'
            className='form-input-container'
            onChange={handleChange}
          />
          <p className='error'>{errors.Phone}</p>
        </label>
        <div className=''>
          <button className='submit_btn' type='submit'>
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatRegForm;
